<template>
  <div>
    <a-button :size="size" style="width: 100%" @click="openModal">
      {{ checkedValue.length > 0 ? `已选择 (${checkedValue.length})` : "选择" }}
    </a-button>

    <a-modal
      :visible="modalVisible"
      title="选择 ursa"
      :width="1200"
      :destroyOnClose="true"
      :footer="null"
      @cancel="modalVisible = false"
    >
      <div style="height: 600px; width: 100%; overflow: auto">
        <hot-table ref="hotTable" :settings="hotTableConfig" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import "handsontable/dist/handsontable.full.css";
import hotTableConfig, { dataSource } from "./hotTableConfig";
import { registerAllModules } from "handsontable/registry";
import "./index.css";

registerAllModules();

export default {
  components: { HotTable },
  props: ["value", "size"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      hotTableConfig: {
        data: dataSource,
        rowHeaders: true,
        colHeaders: true,
        readOnly: true,
        licenseKey: "non-commercial-and-evaluation",
        beforeKeyDown: this.beforeKeyDown,
      },
      checkedValue: [],
      modalVisible: false,
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
      this.$nextTick(() => {
        const hotInstance = this.$refs.hotTable.hotInstance;
        for (const value of this.checkedValue) {
          for (const row in dataSource) {
            for (const col in dataSource[row]) {
              if (value === dataSource[row][col]) {
                const cellMeta = hotInstance.getCellMeta(row, col);
                cellMeta.className = "checked-cell";
                hotInstance.setCellMeta(row, col, cellMeta);
              }
            }
          }
        }
        hotInstance.render();
      });
    },
    handleChange() {
      const hotInstance = this.$refs.hotTable.hotInstance;
      const rowCount = hotInstance.countRows();
      const colCount = hotInstance.countCols();
      const checkedValue = [];
      for (let row = 0; row < rowCount; row++) {
        for (let col = 0; col < colCount; col++) {
          const cellMeta = hotInstance.getCellMeta(row, col);
          if (cellMeta.className === "checked-cell") {
            const value = hotInstance.getDataAtCell(row, col);
            checkedValue.push(value);
          }
        }
      }
      this.checkedValue = checkedValue;
      this.$emit("change", checkedValue);
    },
    beforeKeyDown(event) {
      if (event.key === " ") {
        const hotInstance = this.$refs.hotTable.hotInstance;
        for (const selection of hotInstance.getSelected()) {
          const [startRow, startCol, endRow, endCol] = selection;

          for (let row = startRow < 0 ? 0 : startRow; row <= endRow; row++) {
            for (let col = startCol < 0 ? 0 : startCol; col <= endCol; col++) {
              const cellMeta = hotInstance.getCellMeta(row, col);
              cellMeta.className = "checked-cell";
              hotInstance.setCellMeta(row, col, cellMeta);
            }
          }
        }

        hotInstance.render();
        this.handleChange();
      } else if (event.key === "Backspace") {
        const hotInstance = this.$refs.hotTable.hotInstance;
        for (const selection of hotInstance.getSelected()) {
          const [startRow, startCol, endRow, endCol] = selection;

          for (let row = startRow < 0 ? 0 : startRow; row <= endRow; row++) {
            for (let col = startCol < 0 ? 0 : startCol; col <= endCol; col++) {
              const cellMeta = hotInstance.getCellMeta(row, col);
              cellMeta.className = undefined;
              hotInstance.setCellMeta(row, col, cellMeta);
            }
          }
        }

        hotInstance.render();
        this.handleChange();
      }
    },
  },
  mounted() {
    if (Array.isArray(this.value)) {
      this.checkedValue = this.value;
    }
  },
};
</script>

<style scoped></style>
